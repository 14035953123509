import lazyComponent from 'public/src/services/lazyComponent/lazy-hydrate' // 组件懒加载

const componentsMap = {
  // 轮播图
  'SideslipImage': () => import(/* webpackChunkName: "bff-store-sideslip-image" */'./sideslip-image/Index.vue'),
  // 单图 双图 三图 四图
  'StaticImageCardController': () => import(/* webpackChunkName: "bff-store-static-image" */'./static-image/StaticImageFactory.js'),
  // 一行三品牌
  'BrandRecommend': () => import(/* webpackChunkName: "bff-store-brand-recommend" */'./brand-recommend/index'),
  // 店铺品类推荐
  'StoreCategoryDisplayRecommend': () => import(/* webpackChunkName: "bff-store-category-display-recommend" */'./store-category-display-recommend/store-category-display-recommend.vue'),
  // 单活动banner
  'StorePageSingleActivityBanner': () => import(/* webpackChunkName: "bff-store-single-activity-banner" */'./activity-banner/single-activity-banner.vue'),
  // 品类展示banner
  'CategoryDisplay': () => import(/* webpackChunkName: "bff-store-category-display" */'./category-display-comp/index.vue'),
  // 店铺4图和2.5图
  'StoreSideslipImage': () => import(/* webpackChunkName: "bff-ccc-store-sideslip-image" */'./sideslip-image-with-abt/index.vue'),
  // 店铺视频组件
  'StoreVideo': () => import(/* webpackChunkName: "bff-ccc-store-video" */'./store-video/StoreVideo.vue'),
  // 店铺热搜词组件
  'StoreHotSearchtermRecommend': () => import(/* webpackChunkName: "bff-ccc-store-hot-search" */'./store-hot-search/StoreHotSearch.vue'),
  // 品类热度推荐
  'StoreCategoryRecommend': () => import(/* webpackChunkName: "bff-ccc-store-category-recommend" */'./store-category-recommend/StoreCategoryRecommend.vue'),
  // 平台/店铺 横向券
  'StoreHorizontalCoupon': () => import(/* webpackChunkName: "bff-ccc-store-coupon-list" */'./coupon/index.vue'),
  // 平台/店铺 纵向券
  'StoreVerticalCoupon': () => import(/* webpackChunkName: "bff-ccc-store-coupon-list" */'./coupon/index.vue'),
  // 品牌展示banner
  'BrandDisplay': () => import(/* webpackChunkName: "bff-store-brand-display" */'./brand-display-comp/index.vue'),
  // 活动小banner（自定义商卡）
  'ActivityBanner': () => import(/* webpackChunkName: "bff-ccc-activity-banner" */'./activity-banner/index.vue'),
  // 店铺闪购组件（自定义商卡）
  'StoreFlashSale': () => import(/* webpackChunkName: "bff-ccc-store-flash-sale" */'./store-flash-sale/StoreFlashSale.vue'),
  // 店铺lookbook组件（自定义商卡）
  'StoreLookbook': () => import(/* webpackChunkName: "bff-ccc-store-lookbook" */'./look-book/index.vue'),
  // 品类品牌单品组件（自定义商卡）
  'CategoryBrandItem': () => import(/* webpackChunkName: "bff-ccc-store-category-brand-item" */'./category-brand-item/CategoryBrandItem.vue'),
  // 搭配展示组件（自定义商卡）
  'CollocationDisplay': () => import(/* webpackChunkName: "bff-ccc-store-collocation-display" */'./collocation-display-comp/CollocationDisplay.vue'),
  // 品类商品列表（v3商卡）
  'CategoryItemsDynamic': () => import(/* webpackChunkName: "bff-ccc-category-items-dynamic" */'./category-items-dynamic/index.vue'),
  // 自选横滑商品（v3商卡）
  'SelectedHorizontalProductsDynamic': () => import(/* webpackChunkName: "bff-ccc-product-items" */'./selected-horizontal-products-dynamic/index.vue'),
  // 店铺单品（v3商卡）
  'SingleProduct': () => import(/* webpackChunkName: "bff-ccc-store-single-product" */'./single-product/SingleProduct.vue'),
  // 店铺dailynew组件（v3商卡）
  'StoreDailynew': () => import(/* webpackChunkName: "bff-ccc-store-daily-new" */'./daily-new/index.vue'),
  // 店铺促销活动组件（v3商卡）
  'ShopActivity': () => import(/* webpackChunkName: "bff-ccc-store-shop-activity" */'./shop-activity/ShopActivity.vue'),
  // 店铺纵向列表（v3商卡）
  'StoreVerticalItems': () => import(/* webpackChunkName: "bff-ccc-vertical-items" */'./vertical-items/index.vue'),
  // 店铺横向列表（v3商卡）
  'StoreHorizontalItems': () => import(/* webpackChunkName: "bff-ccc-horizontal-items" */'./horizontal-items/index.vue'),
  // 店铺装修热销榜（v3商卡）
  'StoreRankHotSale': () => import(/* webpackChunkName: "bff-ccc-store-rank-hot-sale" */'./rank-hot-sale/RankHotSale.vue'),
  // 店铺装修上新（v3商卡）
  'StoreNewArrivalList': () => import(/*  webpackChunkName: "bff-ccc-store-new-arrival-list" */'./new-arrival-list/NewArrivalList.vue'),
  // 店铺折扣商品组件（v3商卡）
  'StoreDiscountProductsRecommend': () => import(/* webpackChunkName "bff-ccc-store-discount-products-recommend" */'./store-discount-products-recommend/StoreDiscountProductsRecommend.vue'),
  // 推荐商品组件（v3商卡）
  'RecommendFlow': () => import(/* webpackChunkName: "bff-ccc-store-recommend-flow" */'public/src/pages/components/ccc/components/recommend-flow/Index.vue'),
  // 店铺关注引导组件（homeTab插坑，非cccx组件）
  'StoreFollow': () => import(/* webpackChunkName: "bff-ccc-store-follow" */'../../../store_pages/storeBanner/FlowFollow.vue'),
}

const components = Object.entries(componentsMap).reduce((prev, cur) => {
  prev[`${cur[0]}`] = lazyComponent({
    // propWhole: true,
    componentFactory: cur[1],
    customProps: {
      index: 'number',
      propData: 'object',
      propHooks: 'object',
      content: 'array',
      context: 'object',
      sceneData: 'object',
      cateLinks: 'object',
      language: 'object',
      brand: 'string',
      isLast: 'boolean',
      isLoad: 'boolean',
      lazyForceShow: 'boolean'
    }
  })
  if (['HorizontalAnchor', 'HorizontalAnchorImage', 'Welfare'].includes(cur[0])) {
    prev[`${cur[0]}`] = cur[1]
  }
  return prev
}, {})

export default components
